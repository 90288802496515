import React from "react"
import { useState, useMemo } from "react"
import { useKeycloak } from "@react-keycloak/web"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "./Loading"
import { addItem, removeItem } from "../features/cart/cartSlice"
import { useDispatch, useSelector } from "react-redux"
import {
    addItem as orderAddItem,
    removeItem as orderRemoveItem,
    setAmount,
    clearOrder,
} from "../features/order/orderSlice"
import { Box, Tabs, Tab, Typography } from "@mui/material"
import TabPanel from "./TabPanel"
import StyledTable from "../components/StyledTable"
import { IconButton, Stack, TextField, Button, Snackbar, Alert } from "@mui/material"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"

import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

import OrderHistory from "./OrderHistory"

import { rowActionCell } from "../utils/utils"

const addOrderMutation = gql`
    mutation ($positions: [orderInputType!]!) {
        addOrder(positions: $positions) {
            num
        }
    }
`

const Orders = () => {
    const dispatch = useDispatch()

    const { orderItems } = useSelector(store => store.order)

    const { keycloak, initialized } = useKeycloak()

    const [addOrder, { data: order_data, loading: order_loading, error: order_error }] = useMutation(addOrderMutation, {
        fetchPolicy: "no-cache", // Doesn't check cache before making a network request
        context: {
            headers: {
                Authorization: `Bearer ${keycloak.token}`,
            },
        },
    })

    const [state, setState] = React.useState({ open: false, message: "", severity: "info" })
    const { open, message, severity } = state

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setState({ ...state, open: false })
    }

    const handleOrder = e => {
        e.preventDefault()
        const positions = orderItems.map(item => {
            return { id: item.id, pos: item.pos }
        })
        if (positions.length === 0) {
            setState(previousState => {
                return {
                    ...previousState,
                    open: true,
                    message: `Отсутствуют позиции в заказе`,
                    severity: "error",
                }
            })
            return
        }
        addOrder({ variables: { positions } })
            .then(() => {})
            .catch(err => {
                console.log(err)
            })

        dispatch(clearOrder())
    }

    const cachedOrderItems = useMemo(() => {
        return orderItems
    }, [orderItems])

    const columns = useMemo(
        () => [
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Линия
                    </Typography>
                ),
                accessor: "line", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return <Typography sx={{ width: "150px", minWidth: "150px" }}>{value}</Typography>
                },
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        PN
                    </Typography>
                ),
                accessor: "pn",
                Cell: ({ row: { original }, value }) => {
                    return <Typography sx={{ width: "150px", minWidth: "150px" }}>{value}</Typography>
                },
                disableGroupBy: true,
            },

            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Количество
                    </Typography>
                ),
                accessor: "amount",
                disableSortBy: true,
                Cell: ({ row: { original }, value: initialValue }) => {
                    const [value, setValue] = useState(initialValue)

                    return (
                        <Stack direction="row" justifyContent="flex-end">
                            <TextField
                                id="amount-req"
                                variant="outlined"
                                size="small"
                                type="number"
                                value={value}
                                InputProps={{
                                    inputProps: {
                                        style: { textAlign: "right" },
                                    },
                                }}
                                onChange={e => {
                                    setValue(e.target.value)
                                }}
                                onBlur={() => {
                                    dispatch(setAmount({ id: original.id, pos: original.pos, amount: value }))
                                }}
                            />
                        </Stack>
                    )
                },
                alignHeader: "flex-end",
                disableGroupBy: true,
            },

            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Ед. изм.
                    </Typography>
                ),
                accessor: "pcs",
                disableSortBy: true,
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Цена
                    </Typography>
                ),
                accessor: "price", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return (
                        <Typography align="right" sx={{ width: "150px", minWidth: "150px" }}>
                            {value}
                        </Typography>
                    )
                },
                disableSortBy: true,
                alignHeader: "flex-end",
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Валюта
                    </Typography>
                ),
                accessor: "val", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return (
                        <Typography align="right" sx={{ width: "150px", minWidth: "150px" }}>
                            {value}
                        </Typography>
                    )
                },
                disableSortBy: true,
                alignHeader: "flex-end",
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Сумма
                    </Typography>
                ),
                accessor: "sum", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return (
                        <Typography align="right" sx={{ width: "150px", minWidth: "150px" }}>
                            {value}
                        </Typography>
                    )
                },
                disableSortBy: true,
                alignHeader: "flex-end",
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Дата отгрузки
                    </Typography>
                ),
                accessor: "delivery_date", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return (
                        <Typography align="right" sx={{ width: "150px", minWidth: "150px" }}>
                            {value}
                        </Typography>
                    )
                },
                disableSortBy: true,
                disableGroupBy: true,
            },
        ],
        []
    )

    const removeItemFromOrder = original => {
        dispatch(
            orderRemoveItem({
                id: original.id,
                pos: original.pos,
            })
        )
    }

    return (
        <>
            {/* <Typography variant="h4">Текущий заказ</Typography> */}
            <StyledTable
                columns={columns}
                data={cachedOrderItems}
                onRowClick={(event, rowData) => {}}
                hooks={hooks => {
                    hooks.visibleColumns.push(columns => [
                        ...columns,
                        rowActionCell({
                            IconComponent: DeleteOutlineOutlinedIcon,
                            title: "Удаление позиции из заказа",
                            question: "Вы подтверждаете удаление позиции из формируемого заказа?",
                            dispatchAction: removeItemFromOrder,
                        }),
                    ])
                }}
                actions={[]}
                globalSearch={false}
            />
            <Stack direction="row">
                <Button onClick={handleOrder}>Создать заказ</Button>{" "}
                <Button
                    onClick={() => {
                        dispatch(clearOrder())
                    }}
                >
                    Очистить заказ
                </Button>
            </Stack>
            {order_loading && <Loading />}
            {order_error && <p>{`Error: ${order_error.message}`}</p>}
            {order_data && <p>{`Заказ был успешно сформирован: ${order_data.addOrder.num}`}</p>}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                <Alert severity={severity} onClose={handleCloseSnackBar} sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Orders
