import React from "react"
import OrderHistory from "../components/OrderHistory"
import CurrentOrder from "../components/CurrentOrder"
import { Box, Tabs, Tab } from "@mui/material"
import TabPanel from "../components/TabPanel"
import UnderConstruction from "../components/UnderConstruction"

const OrdersPage = () => {
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const a11yProps = index => {
        return {
            id: `orders-tab-${index}`,
            "aria-controls": `orders-tabpanel-${index}`,
        }
    }

    return (
        <>
            <Box>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Новый заказ" {...a11yProps(0)} />
                        <Tab label="Согласованные заказы" {...a11yProps(1)} />
                        <Tab label="Архив заказов" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0} aria="orders">
                    <CurrentOrder />
                </TabPanel>
                <TabPanel value={value} index={1} aria="orders">
                    <OrderHistory />
                </TabPanel>
                <TabPanel value={value} index={2} aria="orders">
                    <OrderHistory status={"В архиве"} />
                </TabPanel>
            </Box>
        </>
    )
}
export default OrdersPage
